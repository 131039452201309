<template>
	<div class="wrap">
		<!-- <el-page-header @back="$router.go(-1)" content="商户设置" class="margin-bottom margin-top-xs"></el-page-header> -->
		<div class="form_box">
			
			<el-form ref="form" :model="form" label-width="130px">

				<el-form-item label="飞鹅用户名" prop="user" :rules="rules.required">
					<el-input placeholder="请输入飞鹅用户名" v-model="form.user"></el-input>
					<div class="text-sm text-grey">
						<div>请在<a class="text-red" href="https://admin.feieyun.com/login.php" target="_blank">https://admin.feieyun.com/login.php</a>登录注册</div>
						<div>如果已经添加了打印机，可点击测试</div>
					</div>
				</el-form-item>

				<el-form-item label="打印机编号SN" prop="sn" :rules="rules.required">
					<el-input placeholder="请输入飞鹅打印机底部SN码" v-model="form.sn"></el-input>
					<div class="text-sm text-grey">
						<div>请在打印机底部查看</div>
					</div>
				</el-form-item>
				<el-form-item label="打印机UKEY" prop="ukey" :rules="rules.required">
					<el-input placeholder="请输入飞鹅UKEY" v-model="form.ukey"></el-input>
					<div class="text-sm text-grey">
						<div>注册成功后在个人中心-&gt;用户信息查看UKEY</div>
					</div>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="onSubmit">保存</el-button>
					<el-button @click="testPrint()">测试打印机</el-button>
					<div class="text-grey text-sm">目前系统暂仅支持飞鹅网络打印机</div>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../common/common.rules";
	export default {
		data() {
			return {
				rules,
				form: {
					id: undefined,
					user: '',
					ukey: '',
					sn: ''
				},
			};
		},
		computed: {

		},
		mounted() {
			this.$api.system.getPrinter().then(res => {
				if (res.data) {
					this.form = res.data;
				}
			})
		},
		methods: {
			testPrint() {
				const loading = this.$loading();
				this.$api.system.testPrint(this.form).then(res => {
					loading.close()
					if (res.code == 200) {
						this.$message.success(res.msg)
					} else {
						this.$message.error(res.msg)
					}
				}).catch(err => {
					loading.close()
					this.$alert(err);
				})
			},
			onSubmit() {
				this.$refs.form.validate(valid => {
					if (valid) {
						const loading = this.$loading();
						this.$api.system.savePrinter(this.form).then(res => {
							loading.close()
							if (res.code == 200) {
								this.$message.success('保存成功');
							} else {
								this.$message.error(res.msg)
							}
						}).catch(err => {
							loading.close()
							this.$alert(err);
						})
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});

			},

		}
	};
</script>
<style scoped lang="scss">
	.form_box {
		max-width: 600px;
		padding-top: 30px;
	}
</style>
